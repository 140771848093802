import React from 'react'
import SEO from 'components/elements/SEO/SEO'

export default function PrivacyCookiePage() {
  return (
    <>
      <SEO title="Privacy &amp; Cookie Policy" />
      <section className="closer" id="privacy-cookies-policy">
        <h1>Informativa e Consenso sul trattamento dei dati personali</h1>
        <div className="container">
          <p>
            Gent. utente, in questa sezione sono contenute le informazioni relative al trattamento dei dati personali
            degli utenti del sito www.ing-group.it .
          </p>
          <p>
            La presente informativa ha valore anche ai fini dell’articolo 13 del Regolamento UE n. 2016/679, relativo
            relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali nonché alla
            circolazione di tali dati, per i soggetti che interagiscono con il sito summenzionato.
          </p>
          <p>
            Scopo del presente documento è fornire indicazioni circa le modalità, i tempi e la natura delle informazioni
            che i titolari del trattamento devono fornire agli utenti al momento della connessione al sito
            indipendentemente dagli scopi del collegamento stesso, secondo la legislazione Italiana ed Europea.
          </p>
          <h3>Soggetti del Trattamento</h3>
          <p>
            Il titolare del trattamento è la ING Group SRL, con sede legale in Via Milite Ignoto, 20, 80078 Pozzuoli
            (NA). P. IVA: 06836631215.
          </p>
          <h3>Luoghi del Trattamento</h3>
          <p>
            I dati personali trattati dalla ING Group SRL attraverso il sito www.ing-group.it sono esaminati presso le
            sedi operative del Titolare e in ogni altro luogo in cui le parti coinvolte nel trattamento siano
            localizzate. I dati dell’utente potrebbero essere trasferiti in un paese diverso da quello in cui l’utente
            si trova al momento dell’acquisizione del dato.
          </p>
          <h3>Tipologia di Dati Trattati</h3>
          <p>Le categorie di dati degli utenti che il presente servizio raccoglie sono: </p>
          <h4>Dati conferiti volontariamente</h4>
          <p>
            Il sito può raccogliere altri dati in caso di utilizzo volontario di servizi da parte degli utenti, quali
            servizi di commenti, di comunicazione (moduli per contatti, box commenti), e verranno utilizzati
            esclusivamente per l’erogazione del servizio richiesto:
          </p>
          <li>nome e cognome</li>
          <li>indirizzo di spedizione</li>
          <li>indirizzo di residenza (se diverso da quello di spedizione)</li>
          <li>indirizzo email</li>
          <li>numero di telefono</li>
          <p>Per maggiori informazioni sulla tipologia di dati raccolti si veda la sezione dedicata.</p>
          <h3>Finalità e Base Giuridica del Trattamento</h3>
          <p>I dati personali che ci fornisce saranno utilizzati per: </p>
          <li>a) esecuzione del servizio e di tutte le attività ad esso connesse;</li>
          <li>
            b) adempimento degli obblighi previsti dalla legge, regolamenti, normativa applicabile e altre disposizioni
            impartite da autorità investite dalla legge e da organi di vigilanza e controllo.
          </li>
          <p>
            Raccolta di dati e informazioni in forma esclusivamente aggregata e anonima al fine di verificare il
            corretto funzionamento del sito. Nessuna di queste informazioni è correlata alla persona fisica-Utente del
            sito, e non ne consentono in alcun modo l’identificazione.
          </p>
          <p>Entrambe le finalità dei precedenti punti non hanno bisogno del suo consenso espresso.</p>
          <li>
            svolgimento di attività di marketing e promozionali di prodotti e servizi del titolare, comunicazioni
            commerciali, sia con mezzi automatizzati senza intervento dell’operatore (es. sms, fax, mms, posta
            elettronica ecc.) che tradizionali (tramite telefono, posta);
          </li>
          <p>
            Il trattamento dei dati personali per le finalità di cui ai punti appena riferiti{' '}
            <b>richiede il suo consenso espresso</b>. Detto consenso riguarda sia le modalità di comunicazione
            automatizzate che quelle tradizionali sopra descritte. Lei avrà sempre il diritto di opporsi in maniera
            agevole e gratuitamente, in tutto o anche solo in parte al trattamento dei Suoi dati per dette finalità,
            escludendo ad esempio le modalità automatizzate di contatto ed esprimendo la sua volontà di ricevere
            comunicazioni commerciali e promozionali esclusivamente attraverso modalità tradizionali di contatto.
          </p>
          <h3>Natura del Conferimento dei Dati</h3>
          <p>
            NATURA OBBLIGATORIA: Il conferimento dei dati personali relativi alle lettere a) e b) è obbligatorio, visto
            che sono limitati alla fornitura del servizio richiesto e/o all’adempimento degli obblighi previsti dalle
            leggi. Pertanto se ne rifiuta il conferimento, anche parziale, saremo impossibilitati a instaurare e gestire
            il rapporto stesso.
          </p>
          <p>
            NATURA FACOLTATIVA: Il conferimento dei dati personali relativi alle lettere c) e d) è facoltativo, pertanto
            il Suo eventuale rifiuto di fornire tali dati non compromette il servizio, comporterebbe solo
            l’impossibilità a porre in essere le finalità ivi descritte.
          </p>
          <h3>Modalità di Trattamento dei Dati</h3>
          <p>
            Il Titolare utilizza adeguate misure di sicurezza per evitare accesso, divulgazione, modifica o distruzione
            dei Dati Personali effettuati in modo illecito.
          </p>
          <p>Il trattamento avviene con strumenti informatici e/o telematici. </p>
          <h3>Comunicazione e Diffusione</h3>
          <p>
            I Suoi dati personali potranno essere comunicati, nei limiti strettamente correlati al servizio e alle
            modalità del suo consenso, alle seguenti categorie di soggetti:
          </p>
          <li>
            soggetti a cui tale comunicazione deve essere effettuata al fine di adempiere o per esigere l’adempimento di
            specifici obblighi previsti da leggi, da regolamenti e/o dalla normativa comunitaria (autorità giudiziaria);
          </li>
          <li>
            società appartenenti al Gruppo del Titolare, che agiscono in qualità di responsabili del trattamento o per
            finalità di sicurezza od ottimizzazione del servizio;
          </li>
          <li>
            persone fisiche e/o giuridiche esterne che forniscono servizi strumentali alle attività del Titolare per le
            finalità di cui al precedente punto 1. (es. fornitori, consulenti, società, enti, studi professionali). Tali
            soggetti opereranno in qualità di responsabili del trattamento.
          </li>
          <h3>Trasferimento dei dati raccolti a terze parti</h3>
          <p>
            I dati rilevati dal sito generalmente non vengono forniti a terzi, tranne che in casi specifici: legittima
            richiesta da parte dell’autorità giudiziaria e nei soli casi previsti dalla legge; qualora sia necessario
            per la fornitura di uno specifico servizio richiesto dell’Utente; per l’esecuzione di controlli di sicurezza
            sicurezza o di ottimizzazione del sito.
          </p>
          <h3>Trasferimento dei dati in paesi extra UE</h3>
          <p>
            Il presente servizio potrebbe condividere alcuni dei dati raccolti con servizi localizzati al di fuori
            dell’area dell’Unione Europea. In particolare con Google, Facebook e Microsoft (LinkedIn) tramite i social
            plugin e il servizio di Google Analytics. Il trasferimento è autorizzato in base a specifiche{' '}
            <a
              href="http://www.garanteprivacy.it/home/provvedimenti-normativa/normativa/normativa-comunitaria-e-intenazionale/trasferimento-dei-dati-verso-paesi-terzi"
              target="_blank"
              rel="noopener noreferrer"
            >
              decisioni dell’Unione Europea e del Garante per la tutela dei dati personali
            </a>
            , in particolare la decisione 1250/2016 (
            <a
              href="http://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A32016D1250"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Shield
            </a>{' '}
            -{' '}
            <a
              href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/5306161"
              target="_blank"
              rel="noopener noreferrer"
            >
              pagina informativa del Garante italiano
            </a>
            ), per cui non occorre ulteriore consenso. Le aziende sopra menzionate garantiscono la propria adesione al
            Privacy Shield.
          </p>
          <h3>Cookie</h3>
          <p>
            Questo servizio fa uso di cookie, file di testo che vengono registrati sul terminale dell’utente oppure che
            consentono l’accesso ad informazioni sul terminale dell’utente. I cookie permettono di conservare
            informazioni sulle preferenze dei visitatori, sono utilizzati al fine di verificare il corretto
            funzionamento del sito e di migliorarne le funzionalità personalizzando il contenuto delle pagine in base al
            tipo del browser utilizzato, oppure per semplificarne la navigazione automatizzando le procedure (es. Login,
            lingua sito), ed infine per l’analisi dell’uso del sito da parte dei visitatori.
          </p>
          <p>Il presente sito fa uso delle seguenti categorie di cookie:</p>
          <li>
            cookie tecnici, utilizzati al solo fine di effettuare la trasmissione di una comunicazione elettronica, per
            garantire la corretta visualizzazione del sito e la navigazione all’interno di esso. Inoltre, consentono di
            distinguere tra i vari utenti collegati al fine di fornire un servizio richiesto all’utente giusto (Login),
            e per motivi di sicurezza del sito. Alcuni di questi cookie sono eliminati alla chiusura del browser (cookie
            di sessione), altri hanno una durata maggiore (come ad esempio il cookie necessario per conservazione il
            consenso dell’utente in relazione all’uso dei cookie, che dura 1 anno). Per questi cookie non occorre
            consenso;
          </li>
          <li>
            cookie di analisi, utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma
            aggregata, sul numero degli utenti e su come questi visitano il sito stesso. Sono assimilati ai cookie
            tecnici se il servizio è anonimizzato.
          </li>
          <li>
            cookie di profilazione e marketing, utilizzati esclusivamente da terze parti diverse dal titolare del
            presente sito per raccogliere informazioni sul comportamento deli utenti durante la navigazione, e sugli
            interessi e abitudini di consumo, anche al fine di fornire pubblicità personalizzata.
          </li>
          <p>
            Cliccando Accetto sul banner presente al primo accesso, il visitatore acconsente espressamente all’uso dei
            cookie e delle tecnologie similari, e in particolare alla registrazione di tali cookie sul suo terminale per
            le finalità sopra indicate, oppure all’accesso tramite i cookie ad informazioni sul suo terminale.
          </p>
          <h3>Disabilitazione cookie</h3>
          <p>
            L’utente può rifiutare l’utilizzo dei cookie e in qualsiasi momento può revocare un consenso già fornito.
            Poiché i cookie sono collegati al browser utilizzato, POSSONO ESSERE DISABILITATI DIRETTAMENTE DAL BROWSER,
            così rifiutando/revocando il consenso all’uso dei cookie, oppure tramite il banner posto a fondo pagina.
          </p>
          <p>
            LA DISABILITAZIONE DEI COOKIE POTREBBE IMPEDIRE IL CORRETTO UTILIZZO DI ALCUNE FUNZIONI DEL SITO STESSO, in
            particolare i servizi forniti da terze parti potrebbero non essere accessibili, e quindi potrebbero non
            essere visualizzabili:
          </p>
          <li>le mappe di Google.</li>
          <p>
            Le istruzioni per la disabilitazione dei cookies si trovano alle seguenti pagine web:
            <ul>
              <li>
                Google Chrome{' '}
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies
                </a>
              </li>
              <li>
                Mozilla Firefox{' '}
                <a
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                </a>
              </li>
              <li>
                Microsoft Internet Explorer{' '}
                <a
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies
                </a>
              </li>
              <li>
                Apple Safari{' '}
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/
                </a>
              </li>
              <li>
                Opera{' '}
                <a
                  href="https://help.opera.com/en/latest/web-preferences/#cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://help.opera.com/en/latest/web-preferences/#cookies
                </a>
              </li>
            </ul>
          </p>
          <h3>Cookie di terze parti</h3>
          <p>
            Tramite questo servizio si potrebbe accedere a cookie di terze parti (come pulsanti per i social network),
            utilizzati per poter fornire ulteriori servizi e funzionalità ai visitatori e per semplificare l’uso del
            sito stesso, o per fornire pubblicità personalizzata. Questo sito non ha alcun controllo sui loro cookie
            interamente gestiti dalle terze parti e non ha accesso alle informazioni raccolte tramite detti cookie. Le
            informazioni sull’uso dei detti cookie e sulle finalità degli stessi, nonché sulle modalità per l’eventuale
            disabilitazione, sono fornite direttamente dalle terze parti alle pagine indicate di seguito.
          </p>
          <p>
            Si rammenta che generalmente il tracciamento degli utenti non comporta identificazione dello stesso, a meno
            che l’Utente non sia già iscritto al servizio e non sia anche già loggato, nel qual caso si intende che
            l’Utente ha già espresso il suo consenso direttamente alla terza parte al momento dell’iscrizione al
            relativo servizio (es. Facebook).
          </p>
          <p>Il presente sito utilizza cookie delle seguenti terze parti.</p>
          <li>Google Inc.</li>
          <p>
            Per informazioni sull’uso dei dati e sul loro trattamento da parte di Google si raccomanda di prendere
            visione dell’{' '}
            <a href="https://policies.google.com/privacy?hl=it" target="_blank" rel="noopener noreferrer">
              informativa privacy di Google
            </a>
            e delle{' '}
            <a
              href="https://policies.google.com/technologies/partner-sites?hl=it"
              target="_blank"
              rel="noopener noreferrer"
            >
              Modalità di utilizzo dei dati da parte di Google quando si utilizzano siti o app dei partner
            </a>
            .
          </p>
          <li>Google Analytics:</li>
          <p>
            utilizzato per analizzare l’uso del sito da parte degli utenti, compilare report sulle attività del sito e
            il comportamento degli utenti, verificare quanto spesso gli utenti visitano il sito, come il sito viene
            rintracciato e quali pagine sono visitate più frequentemente. Le informazioni sono combinate con
            informazioni raccolte da altri siti al fine di creare un quadro comparativo dell’uso del sito rispetto ad
            altri siti delle medesima categoria.
            <br />
            Dati raccolti: identificativo del browser, data e orario di interazione col sito, pagina di provenienza,
            indirizzo IP.
            <br />
            Luogo di trattamento dei dati: Unione europea essendo attiva l’anonimizzazione del servizio.
          </p>
          <p>
            I dati raccolti non consentono l’identificazione personale degli utenti, e non sono incrociati con altre
            informazioni relative alla stessa persone. Sono trattati in forma aggregata e anonimizzati (troncati
            all’ultimo ottetto). In base ad apposito accordo (DPA) è vietato a Google Inc. (responsabile del
            trattamento) l’incrocio di tali dati con quelli ricavati da altri servizi.
          </p>
          <p>
            Ulteriori informazioni sui cookies di Google Analytics si trovano alla pagina{' '}
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics Cookie Usage on Websites
            </a>
            .
          </p>
          <h3>Periodo di Conservazione dei Dati Personali</h3>
          <p>
            I dati personali saranno conservati per l’intera durata dell’utilizzo del servizio, concluso il quale i dati
            saranno conservati non oltre dieci (10) anni, dopodiché saranno eliminati.
          </p>
          <p>
            I dati raccolti dal sito durante il suo funzionamento sono conservati per il tempo strettamente necessario a
            svolgere le attività precisate. Alla scadenza i dati saranno cancellati o anonimizzati, a meno che non
            sussistano ulteriori finalità per la conservazione degli stessi.
          </p>
          <p>I dati per finalità di analytics (statistica) sono conservati in forma aggregata per 72 mesi.</p>
          <h3>Diritti dell’interessato</h3>
          <p>Nella sua qualità di interessato, ha diritto di:</p>
          <li>
            ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora
            registrati, e la loro comunicazione in forma chiara;
          </li>
          <li>ottenere l’indicazione:</li>
          <ul>
            <li>dell’origine dei dati personali; </li>
            <li>delle finalità e modalità del trattamento; </li>
            <li>della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici;</li>
            <li>
              degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi
              dell’art. 5, comma 2 Codice Privacy e art. 3, comma 1, GDPR;
            </li>
            <li>
              dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che
              possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di
              responsabili o incaricati;
            </li>
          </ul>

          <li>ottenere:</li>
          <ul>
            <li>l’aggiornamento, la rettifica o, quando vi ha interesse, l’integrazione dei dati; </li>
            <li>
              la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge,
              compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono
              stati raccolti o successivamente trattati;
            </li>
            <li>
              l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per
              quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati, eccettuato il caso in
              cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato
              rispetto al diritto tutelato;
            </li>
          </ul>
          <li>opporsi, in tutto o in parte:</li>
          <ul>
            <li>
              per motivi legittimi al trattamento dei dati personali che La riguardano, ancorché pertinenti allo scopo
              della raccolta. Si fa presente che il diritto di opposizione dell’interessato si estende alle
              comunicazioni che avvengano mediante modalità tradizionali e/o automatizzate. Ove applicabili, ha altresì
              i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di
              trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché il diritto di reclamo
              all’Autorità Garante (Garante Privacy –{' '}
              <a
                href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524"
                target="_blank"
                rel="noopener noreferrer"
              >
                link alla pagina del Garante
              </a>
              );
            </li>
          </ul>
          <h3>Modalità di esercizio dei diritti dell’interessato</h3>
          <p>
            Per l’esercizio dei diritti o per domande o informazioni in ordine al trattamento dei Suoi dati e alle
            misure di sicurezza adottate, potrà in ogni caso inoltrare alla nostra società la richiesta al seguente
            indirizzo:
          </p>
          <p>
            ING Group SRL
            <br />
            P. IVA 06836631215
            <br />
            Via Milite Ignoto, 20 <br />
            <br />
            80078 Pozzuoli (NA) <br />
          </p>
          <h4> Indirizzo email del Titolare: </h4>
          <a href="mailto:info@ing-group.it">info@ing-group.it</a>
          <h3>Dettagli sul trattamento dei Dati Personali</h3>
          <p>I Dati Personali sono raccolti per le seguenti finalità e utilizzando i seguenti servizi:</p>
          <h4>Modulo di ordine</h4>
          <p>
            L’Utente, compilando con i propri Dati il modulo di ordine, acconsente al loro utilizzo per rispondere alle
            richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall’intestazione del modulo.
          </p>
          <p>
            Dati Personali raccolti: CAP, Cognome, E-mail, Indirizzo, Nazione, Nome, Numero di telefono, Codice Fiscale
            o Partita IVA, Provincia, Stato, ultime 4 cifre della Carta di Credito utilizzata e varie tipologie di Dati
            secondo quanto specificato dalla privacy policy del servizio.
          </p>
          <h4>Contatto via telefono o mail</h4>
          <p>
            Gli Utenti che hanno fornito il proprio numero di telefono potrebbero essere contattati tramite chiamate o
            messaggi SMS o Whatsapp per finalità commerciali, promozionali, di aggiornamento dei contenuti forniti o
            pubblicati, di sicurezza e controllo relative all’uso o all’accesso del proprio account e comunque collegate
            a questa Applicazione, così come per soddisfare richieste di supporto.
          </p>
          <p>Dati Personali raccolti: numero di telefono e indirizzo e-mail.</p>
          <h3>Gestione dei pagamenti</h3>
          <p>
            I servizi di gestione dei pagamenti permettono a questa Applicazione di processare pagamenti tramite carta
            di credito, bonifico bancario o altri strumenti. I dati utilizzati per il pagamento vengono acquisiti
            direttamente dal gestore del servizio di pagamento richiesto senza essere in alcun modo trattati da questa
            Applicazione. Alcuni di questi servizi potrebbero inoltre permettere l’invio programmato di messaggi
            all’Utente, come email contenenti fatture o notifiche riguardanti il pagamento.
          </p>
          <h4>Stripe (Stripe Inc)</h4>
          <p>Stripe è un servizio di pagamento fornito da Stripe Inc.</p>
          <p>
            Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
            servizio.
          </p>
          <p>
            Luogo del trattamento: USA –{' '}
            <a href="https://stripe.com/terms/US" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </p>
          <h4>PayPal (Paypal)</h4>
          <p>
            PayPal è un servizio di pagamento fornito da PayPal Inc., che consente all’Utente di effettuare pagamenti
            online.
          </p>
          <p>
            Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
            servizio.
          </p>
          <h3>Gestione indirizzi e invio di messaggi email</h3>
          <p>
            Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di
            qualunque altro tipo, utilizzati per comunicare con l’Utente. Questi servizi potrebbero inoltre consentire
            di raccogliere dati relativi alla data e all’ora di visualizzazione dei messaggi da parte dell’Utente, così
            come all’interazione dell’Utente con essi, come le informazioni sui click sui collegamenti inseriti nei
            messaggi.
          </p>
          <h3>Remarketing e behavioral targeting</h3>
          <p>
            Questo tipo di servizi consente a questa Applicazione ed ai suoi partner di comunicare, ottimizzare e
            servire annunci pubblicitari basati sull’utilizzo passato di questa Applicazione da parte dell’Utente.
            Questa attività viene effettuata tramite il tracciamento dei Dati di Utilizzo e l’uso di Cookie,
            informazioni che vengono trasferite ai partner a cui l’attività di remarketing e behavioral targeting è
            collegata. <br />
            In aggiunta alle possibilità di effettuare l’opt-out offerte dai servizi di seguito riportati, l’Utente può
            optare per l’esclusione rispetto alla ricezione dei cookie relativi ad un servizio terzo, visitando la{' '}
            <a href="http://optout.networkadvertising.org/" target="_blank" rel="noopener noreferrer">
              pagina di opt-out del Network Advertising Initiative
            </a>
            .
          </p>
          <h4>AdWords Remarketing (Google Inc.)</h4>
          <p>
            AdWords Remarketing è un servizio di remarketing e behavioral targeting fornito da Google Inc. che collega
            l’attività di questa Applicazione con il network di advertising Adwords ed il Cookie Doubleclick.
          </p>
          <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
          <p>
            Luogo del trattamento: USA –{' '}
            <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            -{' '}
            <a href="https://www.google.com/settings/ads/onweb/optout" target="_blank" rel="noopener noreferrer">
              Opt out
            </a>
            .
          </p>
          <h3>Statistica</h3>
          <p>
            I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare
            i dati di traffico e servono a tener traccia del comportamento dell’Utente.
          </p>
          <h4>Google Analytics (Google Inc.)</h4>
          <p>
            Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i dati
            Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo sito, compilare report e
            condividerli con gli altri servizi sviluppati da Google.
          </p>
          <p>
            Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio
            network pubblicitario.
          </p>
          <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
          <p>
            Luogo del trattamento: USA –{' '}
            <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            -{' '}
            <a href="https://www.google.com/settings/ads/onweb/optout" target="_blank" rel="noopener noreferrer">
              Opt out
            </a>
            .
          </p>

          <h3>Cookie di profilazione (tracciamento/marketing)</h3>
          <p>
            Questo Sito Web usa Cookie o altri Identificatori per fornire contenuti commerciali personalizzati in base
            al comportamento dell’Utente e per gestire, fornire e tenere traccia degli annunci pubblicitari.
          </p>
          <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
          <p>
            Luogo del trattamento: Stati Uniti –{' '}
            <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            . Soggetto aderente al Privacy Shield.
          </p>
        </div>
      </section>
    </>
  )
}
